// Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// customize some Bootstrap variables
$primary: #215e21 !default;
$secondary: #2E842E !default;
$success: #812E1E !default;
$info: #2c862c !default;
//$warning: #dea747 !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$navbar-light-color: rgba($white, .55) !default;
$navbar-light-hover-color: rgba($white, .7) !default;
$navbar-light-active-color: rgba($white, .9) !default;
$navbar-light-disabled-color: rgba($white, .3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($white, .1) !default;
$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-300 !default;

$font-size-base: 1.125rem !default;

/*
$h1-font-size:                $font-size-base * 1.50 !default;
$h2-font-size:                $font-size-base * 1.35 !default;
$h3-font-size:                $font-size-base * 1.20 !default;
$h4-font-size:                $font-size-base * 1.10 !default;
$h5-font-size:                $font-size-base * 1.00 !default;
$h6-font-size:                $font-size-base * 0.90 !default;
*/

$input-btn-focus-blur: 5px !default;
// bug in _variables.scss
// select control didn't use the "blur" value
$form-select-focus-box-shadow: 0 0 $input-btn-focus-blur 0.25rem rgb(33 94 33 / 25%) !default;

$custom-control-indicator-size: 1.5rem !default;

$input-group-addon-bg: #dff1df !default;

.bg-rxtitle {
  background-color: $gray-200 !important;
}


// the ~ allows you to reference things in node_modules
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~bootstrap/scss/bootstrap";

$menuBarColor: darken(#C7A12A, 5%) !default;


html {
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  text-align: left;
}

h1, h2, h3, h4, h5, h6, .panel-title {
  font-family: 'Fjalla One', sans-serif;
  color: $success;
}

td {
  vertical-align: top
}

header {
  margin: 0 14px;
}

p { max-width: 60ch; }

footer {
  position: relative;
  font-family: 'Fjalla One', sans-serif;
  font-size: 90%;
  margin: 20px 0 0;
  background: #003300;
  color: #fff;
  padding: 20px;
  min-height: 13em;

  a {
    color: white
  }

  .copyright {
    position: absolute;
    bottom: 10px;
    left: 20px;
  }

  .disclaimer {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: small;
    padding-top: 2em;
  }

  .version {
    font-size: 90%;
    color: #999;
    position: absolute;
    bottom: 10px;
    right: 20px;
    text-align: right;
  }
}

#body-block {
  margin: 0;
}

.box {
  width: 100%;
  padding: 10px 0;
  background-color: #f8f8f8;
}

.box-img {
  width: 100%;
  margin: 5px 0;
  border: 3px solid #215e21;
  border-radius: 5px;
  padding: 4px;
}

.box.plain {
  border: 0;
  background: transparent;
}

@include media-breakpoint-down(sm) {
  .box {
    border-style: solid;
    border-color: #ccc;
    border-width: 0 0 1px 0;
    border-radius: 0;

  }

  .box.last {
    border: 0;
  }

  #body-block {
    margin: 0;
  }
}

.card-header {
  font-size: 120%;
  font-weight: bold;
}

@include media-breakpoint-down(md) {
  .box-minh {
    min-height: 17em;
  }
}

.box h1, .box h2, .box h3 {
  margin-top: 5px;
}

.nobox {
  background: none !important;
  border: none;
  height: auto;
  margin: 0 20px 0 20px;
  padding: 0;
}

.btn-social {
  position: relative;
  padding-left: 4em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.btn-social > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2em;
  line-height: 1.3em;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.btn-fbk {
  color: #fff;
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2)
}

.btn-fbk:focus, .btn-fbk.focus {
  color: #fff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2)
}

.btn-fbk:hover {
  color: #fff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2)
}

.btn-fbk:active, .btn-fbk.active, .open > .dropdown-toggle.btn-fbk {
  color: #fff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2)
}

.btn-fbk:active:hover, .btn-fbk.active:hover, .show > .dropdown-toggle.btn-fbk:hover, .btn-fbk:active:focus, .btn-fbk.active:focus, .show > .dropdown-toggle.btn-fbk:focus, .btn-fbk:active.focus, .btn-fbk.active.focus, .show > .dropdown-toggle.btn-fbk.focus {
  color: #fff;
  background-color: #23345a;
  border-color: rgba(0, 0, 0, 0.2)
}

.btn-fbk:active, .btn-fbk.active, .show > .dropdown-toggle.btn-fbk {
  background-image: none
}

.btn-fbk.disabled, .btn-fbk[disabled], fieldset[disabled] .btn-fbk, .btn-fbk.disabled:hover, .btn-fbk[disabled]:hover, fieldset[disabled] .btn-fbk:hover, .btn-fbk.disabled:focus, .btn-fbk[disabled]:focus, fieldset[disabled] .btn-fbk:focus, .btn-fbk.disabled.focus, .btn-fbk[disabled].focus, fieldset[disabled] .btn-fbk.focus, .btn-fbk.disabled:active, .btn-fbk[disabled]:active, fieldset[disabled] .btn-fbk:active, .btn-fbk.disabled.active, .btn-fbk[disabled].active, fieldset[disabled] .btn-fbk.active {
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2)
}

.btn-fbk .badge {
  color: #3b5998;
  background-color: #fff
}

/*==================================================
 * Effect 2
 * ===============================================*/
.effect2 {
  position: relative;
}

.effect2:before, .effect2:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  box-shadow: 0 15px 10px #777;
  transform: rotate(-3deg);
}

.effect2:after {
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.dropdown-toggle {
  border-radius: 4px 4px 0 0;

}

/*==================================================
 * Navbar
 *=================================================*/

.user_login {
  font-family: 'Fjalla One', sans-serif;
  float: right;
  margin-top: 20px;
  margin-right: 10px;
}

.rxn_xno {
  font-family: 'Fjalla One', sans-serif;
  border-radius: 5px;
  border: 1px solid #a73535;
  padding: 4px 4px 4px 9px;
  letter-spacing: 5px;
  color: #efe;
  background: linear-gradient(0deg, darken($success, 20%), $success);
}

.navbar {
  margin: 0;
  padding: 0 0 4px 0;
  border: 0;

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
    margin-bottom: 4px;
    border-bottom-width: 1px;
    border-bottom-color: #619e61;
    border-bottom-style: solid;
  }
}

nav {
  margin-bottom: 8px;
}

.navbar-brand {
  height: 57px;
  padding-left: 14px;
}

@include media-breakpoint-down(md) {
  .navbar-brand {
    height: 40px !important;
    width: 185px;
  }

  .navbar-brand img {
    height: 40px;
    width: 185px;
    padding: 1px 14px 0 0;
    margin-left: -7px;
  }
}

.navbar-header .navbar-toggler {
  float: right;
  padding: 2px 6px;
  margin: 10px 10px 0 20px;
  color: $gray-100;
  background-color: $menuBarColor;
  border-color: lighten($menuBarColor, 10%);
  font-size: 100%;

  &:focus {
    background-color: $menuBarColor;
    border-color: lighten($menuBarColor, 10%);
  }

  &:hover {
    color: $white;
    background-color: lighten($menuBarColor, 10%);
    border-color: lighten($menuBarColor, 0%);
  }
}

.navbar .navbar-collapse {
  padding: 5px;
}

#navbarMenu {
  background: $menuBarColor;
}
/*
$navBar_stripe1: lighten($menuBarColor, 0%) !default;
$navBar_stripe2: darken($menuBarColor, 30%) !default;

#navbarMenu {
  background: $menuBarColor linear-gradient(135deg, $navBar_stripe1 25%, $navBar_stripe2 25%, $navBar_stripe2 50%, $navBar_stripe1 50%, $navBar_stripe1 75%, $navBar_stripe2 75%, $navBar_stripe2 100%);
  background-size: 28.28px 28.28px;
}
*/

ul.navbar-nav {
  padding: 0;
}

ul.navbar-nav, ul.dropdown-menu {
  li {
    font-family: 'Fjalla One', sans-serif;
    font-size: 120%;

    @include media-breakpoint-down(md) {
      font-size: 100%;
    }
  }
}

.navbar-nav > li > a {
  border-radius: 5px;
  margin-right: 5px;
  color: white;
}

.navbar-nav > li.active > a,
.navbar-nav > li > a.show,
.navbar-nav > li > a:hover {
  background: white;
  color: var(--bs-primary);
}

.nav-link {
  padding: 0 8px;
}

.navbar-header {
  margin-bottom: 5px;
}

.dropdown-menu > li > a,
{
  font-size: 85%;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  color: var(--bs-primary);
}

.dropdown-menu > li.active > a,
.dropdown-menu > li > a:hover {
  background: #eeeeee;
  color: var(--bs-primary);
}


/* Awards Overlay */
table.awards {
  width: 100%
}

.awards td {
  padding: 1em 0;
  text-align: center;
  border: 1px solid #eee;
}

.zoomImageOverlay {
  position: relative;
  display: inline-block;
}

.zoomImageOverlay::before {
  content: '\f00e';
  z-index: 5;
  position: absolute;
  right: 5%;
  bottom: 10%;
  transform: translate(-100%, 0%);
  color: white;
  font-family: 'FontAwesome';
  font: var(--fa-font-solid);
  font-size: 16px !important;
}


.col-form-label,
.col-form-label-lg,
.col-form-label-sm {
  font-weight: bold;
  text-align: right;

  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

